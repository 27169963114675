import { Button, IconButton, TextField } from "@mui/material";
import axios from "axios";
import React, { useContext } from "react";
import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Loader from "./loader/Loader";
import CloseIcon from "@mui/icons-material/Close";
import { AppContext } from "../context/AppContext";

const initialValue = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
};

const ApplyJob = ({ setToggleForm }) => {
  const [formValue, setFormValue] = useState(initialValue);
  const [formError, setFormError] = useState({ value: {}, status: false });
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inputFields, setInputFields] = useState([]);
  const [responseMessage, setResponseMessage] = useState({
    message: "",
    status: true,
  });
  const { data } = useContext(AppContext);

  const handleClickOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    if (responseMessage.status) {
      setToggleForm(false);
    }
  };

  const handleValidate = (data) => {
    const error = { value: {}, status: false };

    const emptyString = /^[a-zA-Z]+[a-zA-Z0-9 !@#\$%\^\&*\)\(+=._-]{1,50}$/;
    const emptyNum = /^[0-9]+[0-9 !@#\$%\^\&*\)\(+=._-]{1,50}$/;

    const fieldRegex = {
      firstName: emptyString,
      lastName: emptyString,
      phoneNumber: emptyNum,
    };

    for (let key in data) {
      const strCheck = fieldRegex[key].test(data[key]);
      if (!strCheck) {
        error.value[key] = `${key} is a required field`;
        error.status = true;
      }
    }
    return error;
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const handleAddField = () => {
    setInputFields([...inputFields, { key: "", value: "" }]);
  };

  const handleCloseField = (index) => {
    const copy = [...inputFields];
    copy.splice(index, 1);
    setInputFields([...copy]);
  };

  const handleChangeInput = (e, index) => {
    const { value, name } = e.target;
    const copy = [...inputFields];
    copy[index][name] = value;
    setInputFields([...copy]);
  };

  console.log("data", data.token);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const checkValidate = handleValidate(formValue);
      setFormError(checkValidate);

      if (!checkValidate.status) {
        setIsLoading(true);

        const customField = inputFields.map((item) => {
          return {
            field_data: {
              keyname: item.key,
              value: item.value,
            },
          };
        });

        const body = {
          first_name: formValue.firstName,
          last_name: formValue.lastName,
          phone_number: formValue.phoneNumber,
        };

        const request = {
          method: "post",
          url: "/api/applicants",
          headers: {
            Authorization: data.token,
            "Content-Type": "application/json",
          },
          data: JSON.stringify(body),
        };

        const res = await axios(request);

        console.log("res", res);
        setInputFields([]);
        setFormValue(initialValue);
        setIsLoading(false);
        setResponseMessage({
          status: true,
          message: "Application submitted successfully!",
        });
        handleClickOpen();
        // toast.success("Application save successfully!");
      }
    } catch (error) {
      setIsLoading(false);
      setResponseMessage({
        status: false,
        message: error?.response?.data?.error,
      });
      handleClickOpen();
      console.log("error", error);
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent sx={{ padding: "26px 21px 0 21px" }}>
            <h1
              className={`colorSuccess ${
                !responseMessage.status && "colorRed"
              }`}
            >
              {responseMessage.message}
            </h1>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleClose} autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div className="py4 maniContainer">
        <div className="mb2">
          <h1 className="mb2">Apply for job</h1>
          <p>Please fill in the fields below with your personal information</p>
        </div>
        <div className="col6 py4">
          <div className="formInput">
            <TextField
              value={formValue.firstName}
              onChange={handleChange}
              helperText={
                formError.value.firstName && formError.value?.firstName
              }
              error={formError.value?.firstName ? true : false}
              name="firstName"
              id="outlined-basic"
              fullWidth
              label="First Name"
              variant="outlined"
            />
          </div>
          <div className="formInput">
            <TextField
              value={formValue.lastName}
              onChange={handleChange}
              helperText={formError.value.lastName && formError.value?.lastName}
              error={formError.value?.lastName ? true : false}
              name="lastName"
              id="outlined-basic"
              fullWidth
              label="Last Name"
              variant="outlined"
            />
          </div>
          <div className="formInput">
            <TextField
              value={formValue.phoneNumber}
              onChange={handleChange}
              helperText={
                formError.value.phoneNumber && formError.value?.phoneNumber
              }
              error={formError.value?.phoneNumber ? true : false}
              name="phoneNumber"
              type={"number"}
              id="outlined-basic"
              fullWidth
              label="Phone Number"
              variant="outlined"
            />
          </div>
          <div>
            {inputFields.map((item, index) => {
              return (
                <div className="formInput" key={index}>
                  <div className="position-r">
                    <div className="dFLex">
                      <div className="col6 pe1">
                        <TextField
                          id="outlined-basic"
                          fullWidth
                          label="Key"
                          variant="outlined"
                          name="key"
                          onChange={(e) => handleChangeInput(e, index)}
                          value={item.key}
                        />
                      </div>
                      <div className="col6 ps1">
                        <TextField
                          id="outlined-basic"
                          fullWidth
                          label="Value"
                          variant="outlined"
                          name="value"
                          onChange={(e) => handleChangeInput(e, index)}
                          value={item.value}
                        />
                      </div>
                    </div>
                    <div className="inputClose">
                      <IconButton onClick={() => handleCloseField(index)}>
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="formInput">
            <Button
              onClick={handleSubmit}
              variant="contained"
              type="submit"
              fullWidth
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplyJob;
