import { Button } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import logo from "../assets/image/logo.png";
import ApplyJob from "./ApplyJob";
import axios from "axios";
import Loader from "./loader/Loader";
import { AppContext } from "../context/AppContext";

const JobDetails = () => {
  const [toggleForm, setToggleForm] = useState(false);
  const [loader, setLoader] = useState(false);
  const { shortName } = useParams();
  const { setData } = useContext(AppContext);
  const [isError, setIsError] = useState({ status: false, message: "" });

  const handleToggle = () => {
    setToggleForm((prev) => !prev);
  };

  const fetchCompany = async () => {
    setLoader(true);
    try {
      const request = {
        method: "get",
        url: `/api/company/data?shortname=${shortName}`,
        headers: {
          "access-control-allow-origin": "*",
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios(request);
      setData(data.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      setIsError({ status: true, message: error.response.data.error });
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetchCompany();
  }, []);

  return (
    <div>
      {loader ? (
        <Loader />
      ) : isError.status ? (
        <h1>Company not found</h1>
      ) : (
        <>
          <div className="jobHeader">
            <div className="maniContainer">
              <div className="dFlex">
                <div className="col8">
                  <h1>Cloud Technology Architecture</h1>
                  <div className="applyBtn mb2">
                    <Button onClick={handleToggle} variant="contained">
                      Apply Now
                    </Button>
                  </div>
                </div>
                <div className="col4">
                  <div>{/* <img src={logo} alt="Logo" /> */}</div>
                </div>
              </div>
            </div>
          </div>
          {toggleForm && <ApplyJob setToggleForm={setToggleForm} />}
          <div className="maniContainer">
            <div className="jobDescription">
              <div className="col8">
                <h1 className="mb1">Job Description</h1>
                <div>
                  <p>
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                    Natus at, placeat quidem sapiente dicta illum? Rem, autem
                    impedit. Quia ullam, consequuntur aperiam necessitatibus
                    sapiente officiis quam quae? Officiis fugiat ipsum odit
                    animi necessitatibus optio facilis maiores, pariatur quaerat
                    explicabo, perferendis velit quam. Tempore deserunt
                    voluptatem aspernatur corrupti repellendus magni iusto?
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default JobDetails;
