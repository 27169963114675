import React, { useContext } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/image/logo2.png";
import { AppContext } from "../context/AppContext";

const Navbar = () => {
  const { data } = useContext(AppContext);

  return (
    <>
      {data?.name ? (
        <div className="navbar">
          <div className="container">
            <div className="dFLex alignItemCenter">
              {/* <div className="logo">
            <Link to={"/"}>
            <img src={logo} alt="" />
            </Link>
          </div> */}
              <div className="companyName">
                <h2>{data?.name}</h2>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Navbar;
