import { Backdrop } from "@mui/material";
import React from "react";
import loadGif from "../../assets/image/loading.gif";

const Loader = () => {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <div className="loader">
        <img src={loadGif} alt="" />
      </div>
    </Backdrop>
  );
};

export default Loader;
